export const act = {
  user: "",
  date: "",
  addressName: "",
  address: "",
  contactName: "",
  contactPhone: "",
  internet: "",
  internetAccess: "",
  hotScheme: "",
  hidroelevator: "",
  gvs: "",
  hot: "",
  hvs: "",
  electrocity: "",
  gvc: "",
  remoteGvc: "",
  hotGvc: "",
  model1: "",
  model2: "",
  desp: "",
  despFromCounter: "",
  transformators1: "",
  transformators2: "",
  art: "",
  desc: "",
};

export const actDict = {
  user: "ИСПОЛНИТЕЛЬ",
  date: "ДАТА АУДИТА",
  addressName: "ОБЪЕКТ",
  address: "АДРЕС",
  contactName: "КОНТАКТНОЕ ЛИЦО",
  contactPhone: "ТЕЛЕФОН",
  internet: "ПРОВАЙДЕР ИНТЕРНЕТА",
  internetAccess:
    "ВОЗМОЖНОСТЬ ПРОВЕДЕНИЯ ПРОВОДНОГО ИНТЕРНЕТА К МАСТЕР-УСТРОЙСТВАМ",
  hotScheme: "СХЕМА ТЕПЛОСНАБЖЕНИЯ",
  hidroelevator: "НАЛИЧИЕ ГИДРОЭЛЕВАТОРА",
  gvs: "СИСТЕМА ГВС",
  hot: "ОТОПЛЕНИЕ",
  hvs: "ХВС",
  electrocity: "НАЛИЧИЕ ЭЛ.ПИТАНИЯ В МЕСТАХ РАЗМЕЩЕНИЯ ОБОРУДОВАНИЯ",
  gvc: "ГВС",
  remoteGvc: "ГВС В ДАЛЬНЕЙ ТОЧКЕ",
  hotGvc: "ОТОПЛЕНИЕ В ДАЛЬНЕЙ ТОЧКЕ",
  model1: "МОДЕЛЬ ТЕПЛОСЧЕТЧИКА",
  model2: "НАЛИЧИЕ ВЫХОДА ДАННЫХ ИЗ ТЕПЛОСЧЕТЧИКА",
  desp: "НАЛИЧИЕ ДИСПЕТЧЕРИЗАЦИИ",
  despFromCounter: "НАЛИЧИЕ ДИСКРЕТНОГО ВЫХОДА С СЧЕТЧИКА ХВС",
  transformators1: "400/5",
  transformators2: "30/5",
  art: "НАЛИЧИЕ СИСТЕМЫ АРТ",
  desc: "ПРИМЕЧАНИЯ",
};

export const actSorted = {
  ОБЩЕЕ: [
    "user",
    "date",
    "addressName",
    "address",
    "contactName",
    "contactPhone",
    "internet",
    "internetAccess",
    "hotScheme",
    "hidroelevator",
    "gvs",
  ],
  "ПОМЕЩЕНИЕ И МЕСТО РАЗМЕЩЕНИЯ ОБОРУДОВАНИЯ В ДАЛЬНИХ ТОЧКАХ": [
    "hot",
    "hvs",
    "electrocity",
  ],
  "МАТЕРИАЛ ТРУБОПРОВОДОВ": [
    "gvc",
    "remoteGvc",
    "hotGvc",
    "model1",
    "model2",
    "desp",
    "despFromCounter",
  ],
  "ТРАНСФОРМАТОРЫ ТОКА": ["transformators1", "transformators2", "art", "desc"],
};

export const disabledInputs = ["user", "date", "addressName", "address"];
